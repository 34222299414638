import { createContext, Dispatch, SetStateAction } from 'react';

import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import eventEmitter from '../utils/eventEmitter';
import { EMITTER_EVENTS } from '../constants';
import { NoticeModal } from '../components/modal/noticeModal';

export type NoticeState = {
  visible: boolean;
  notice: string;
  closeable: boolean;
};

export type NoticeContextState = {
  state: NoticeState;
  setState: Dispatch<SetStateAction<NoticeState>>;
};

const NoticeContext = createContext<NoticeContextState>({
  state: { visible: false, notice: '', closeable: true },
  setState: () => {},
});

type Props = PropsWithChildren<{
  //
}>;

export const NoticeProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<NoticeState>({
    visible: false,
    notice: '',
    closeable: true,
  });

  const value = useMemo(() => ({ state, setState }), [state]);

  useEffect(() => {
    const ln = (newState: NoticeState) => {
      setState({ ...newState });
    };
    eventEmitter.addListener(EMITTER_EVENTS.SHOW_NOTICE, ln);
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.SHOW_NOTICE, ln);
    };
  }, []);

  return (
    <NoticeContext.Provider value={value}>
      {children}
      <NoticeModal
        visible={state.visible}
        notice={state.notice}
        closeable={state.closeable}
        onDismiss={() => {
          state.closeable && setState(s => ({ ...s, visible: false, notice: '' }));
        }}
      />
    </NoticeContext.Provider>
  );
};
