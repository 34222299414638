import { VoucherType } from '../typing';

export const ASSETS = {
  // INGAME
  INGAME_BUTTON: 'assets/atlas/button.png',
  SHINHAN_PNG: 'assets/atlas/shinhan.png',
  SHINHAN_PNG2: 'assets/atlas/shinhan2.png',
  SHINHAN_PNG3: 'assets/atlas/shinhan3.png',
  SHINHAN_PNG4: 'assets/atlas/shinhan4.png',
  SHINHAN_PNG5: 'assets/atlas/shinhan5.png',
  SHINHAN_PNG6: 'assets/atlas/shinhan6.png',

  // UI
  BACKGROUND: 'assets/ui/background.png',
  LIGHT: 'assets/ui/light.png',
  BASKET_HEADER_BANNER: 'assets/ui/basket_header_banner.png',
  BOOKMARK: 'assets/ui/bookmark.png',
  BLUE_BUTTON: '',
  LEFT_BLUE_BUTTON: 'assets/ui/left_button.png',
  RIGHT_BLUE_BUTTON: 'assets/ui/right_button.png',
  BOTTOM_DECO: 'assets/ui/bottom_deco.png',
  CHECK_ICON: 'assets/ui/check_icon.png',
  COMPLETE_BUTTON: 'assets/ui/complete_button.png',
  EXIT_BUTTON: 'assets/ui/exit_button.png',
  EXIT: 'assets/ui/exit.png',
  GAME_BANNER: 'assets/ui/game_banner.png',
  GIFT_ICON: 'assets/ui/gift_icon.png',
  INSTRUCTION_DECO: 'assets/ui/instruction_deco.png',
  INSTRUCTION_HEADER_BANNER: 'assets/ui/instruction_header_banner.png',
  INSTRUCTION_ICON: 'assets/ui/instruction_icon.png',
  METHOD1_ICON: 'assets/ui/method1_icon.png',
  METHOD2_ICON: 'assets/ui/method2_icon.png',
  MUTE_BUTTON: 'assets/ui/mute_button.png',
  UNMUTE_BUTTON: 'assets/ui/unmute_button.png',
  ORANGE_BUTTON: 'assets/ui/orange_button.png',
  REWARD_HEADER_BANNER: 'assets/ui/reward_header_banner.png',
  REWARD_HEADER_GLOW: 'assets/ui/reward_header_glow.png',
  REWARD_MODAL_BD: 'assets/ui/reward_modal_bd.png',
  SHARE_BUTTON: '',
  SHORT_MODAL_BD: 'assets/ui/short_modal_bd.png',
  SPEAKER_ICON: 'assets/ui/speaker_icon.png',
  SPIN_BUTTON: 'assets/ui/spin_button.png',
  TRASH_BANNER: 'assets/ui/trash_banner.png',
  VOUCHER_0K: 'assets/ui/voucher_0k.png',
  VOUCHER_10K: 'assets/ui/voucher_10k.png',
  VOUCHER_20K: 'assets/ui/voucher_20k.png',
  VOUCHER_50K: 'assets/ui/voucher_50k.png',
  VOUCHER_100K: 'assets/ui/voucher_100k.png',
  VOUCHER_200K: 'assets/ui/voucher_200k.png',
  VOUCHER_500K: 'assets/ui/voucher_500k.png',
  VOUCHER_1000K: 'assets/ui/voucher_1000k.png',
  MINI_VOUCHER_0K: '',
  MINI_VOUCHER_10K: 'assets/ui/mini_voucher_10k.png',
  MINI_VOUCHER_20K: 'assets/ui/voucher_20k.png',
  MINI_VOUCHER_50K: 'assets/ui/mini_voucher_50k.png',
  MINI_VOUCHER_100K: 'assets/ui/mini_voucher_100k.png',
  MINI_VOUCHER_200K: 'assets/ui/voucher_200k.png',
  MINI_VOUCHER_500K: 'assets/ui/voucher_500k.png',
  MINI_VOUCHER_1000K: 'assets/ui/voucher_1000k.png',
  VOUCHER_SHADOW: 'assets/ui/voucher_shadow.png',
  WARNING_ICON: 'assets/ui/warning_icon.png',
  YELLOW_BUTTON: 'assets/ui/yellow_button.png',
  STROKE: 'assets/ui/stroke.svg',
  TABLE: 'assets/ui/table.png',
  ARROW: 'assets/ui/arrow.png',
  LOADING_BG: 'assets/ui/loading_bg.png',
  SOL_LOGO: 'assets/ui/sol_logo.png',
  NOTICE_MODAL_BD: 'assets/ui/notice_modal_bd.png',
  LOGO: '',
  LOGO30YEAR: 'assets/ui/logo30year.png',
  BACK_BUTTON: 'assets/ui/back_button.png',
  OUT_OF_TICKET_BANNER: 'assets/ui/out_of_ticket_banner.png',
  OUT_OF_TICKET_QUEST: 'assets/ui/out_of_ticket_quest.png',
  ACTIVE_BUTTON: 'assets/ui/active_button.png',
  DEACTIVE_BUTTON: 'assets/ui/deactive_button.png',
  CHECKED: 'assets/ui/checked.png',
  WELCOME_BD: 'assets/ui/welcome_bd.png',
  WELCOME_DECO: 'assets/ui/welcome_deco.png',
  GIFT: 'assets/ui/gift.png',
};

export const INGAME_ASSETS = {
  BUTTON_SPINE_ATLAS: 'assets/atlas/button.atlas',
  BUTTON_SPINE_JSON: 'assets/atlas/button.json',
  BUTTON_SPINE_IMAGE: 'assets/atlas/button.png',
  ASSETS_ATLAS: 'assets/atlas/shinhan-luckyspin.atlas',
  FONT_ATLAS: 'assets/atlas/font.fnt',
  FONT_IMAGE: 'assets/atlas/font.png',
};

export const VOUCHER_IMAGES = {
  [VoucherType.VOUCHER_0K]: ASSETS.VOUCHER_0K,
  [VoucherType.VOUCHER_10K]: ASSETS.VOUCHER_10K,
  [VoucherType.VOUCHER_20K]: ASSETS.VOUCHER_20K,
  [VoucherType.VOUCHER_50K]: ASSETS.VOUCHER_50K,
  [VoucherType.VOUCHER_100K]: ASSETS.VOUCHER_100K,
  [VoucherType.VOUCHER_200K]: ASSETS.VOUCHER_200K,
  [VoucherType.VOUCHER_500K]: ASSETS.VOUCHER_500K,
  [VoucherType.VOUCHER_1000K]: ASSETS.VOUCHER_1000K,
};

export const MINI_VOUCHER_IMAGES = {
  [VoucherType.VOUCHER_0K]: ASSETS.MINI_VOUCHER_0K,
  [VoucherType.VOUCHER_10K]: ASSETS.MINI_VOUCHER_10K,
  [VoucherType.VOUCHER_20K]: ASSETS.MINI_VOUCHER_20K,
  [VoucherType.VOUCHER_50K]: ASSETS.MINI_VOUCHER_50K,
  [VoucherType.VOUCHER_100K]: ASSETS.MINI_VOUCHER_100K,
  [VoucherType.VOUCHER_200K]: ASSETS.MINI_VOUCHER_200K,
  [VoucherType.VOUCHER_500K]: ASSETS.MINI_VOUCHER_500K,
  [VoucherType.VOUCHER_1000K]: ASSETS.MINI_VOUCHER_1000K,
};
