import { Modal } from './modal';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { Button } from '../button/button';
import { ASSETS } from '../../utils/assetUtils';
import { VoucherItem, VoucherType } from '../../typing';
import classes from './basketModal.module.css';
import { useInventory, useTouchOutside } from '../../hooks';
import dayjs from 'dayjs';
import { VoucherTypeMap } from '../../constants';
import { LoaderOverlay } from '../common/loaderOverlay';
import gamefoxSDK from '../../gamefoxSDK';

type Props = {
  visible: boolean;
  onDismiss: () => void;
};

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: `calc(var(--game-width) * -0.1)`,
};

const filters = [
  'all',
  VoucherType.VOUCHER_1000K,
  VoucherType.VOUCHER_500K,
  VoucherType.VOUCHER_200K,
  VoucherType.VOUCHER_100K,
  VoucherType.VOUCHER_50K,
  VoucherType.VOUCHER_20K,
  VoucherType.VOUCHER_10K,
];

const filterTexts: { [key in string]: string } = {
  all: 'Tất cả',
  [VoucherType.VOUCHER_1000K]: 'eVoucher 1.000.000 VNĐ',
  [VoucherType.VOUCHER_500K]: 'eVoucher 500.000 VNĐ',
  [VoucherType.VOUCHER_200K]: 'eVoucher 200.000 VNĐ',
  [VoucherType.VOUCHER_100K]: 'eVoucher 100.000 VNĐ',
  [VoucherType.VOUCHER_50K]: 'eVoucher 50.000 VNĐ',
  [VoucherType.VOUCHER_20K]: 'eVoucher 20.000 VNĐ',
  [VoucherType.VOUCHER_10K]: 'eVoucher 10.000 VNĐ',
};

export const BasketModal: React.FC<Props> = ({ visible, onDismiss }) => {
  const [currentFilter, setCurrentFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  const [nodeRef, currentRefKey, setCurrentRefKey] = useTouchOutside();
  const [inventory, getInventory] = useInventory();

  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      getInventory()
        .catch(e => {
          //ignore
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [getInventory, visible]);

  const displayVouchers = useMemo<VoucherItem[]>(() => {
    if (!inventory) return [];

    const convertedItems = inventory.items.map(item => ({
      _id: item.id,
      itemId: item.itemId,
      createdAt: dayjs(item.createdAt).format('DD/MM/YYYY'),
      // expiredAt: '30/06/2024',
      type: VoucherTypeMap[item.type],
      data: {
        expiredAt: dayjs(new Date(item.data.expiredAt)).format('DD/MM/YYYY'),
        link: item.data.link,
      },
    }));

    if (currentFilter === 'all') {
      return convertedItems;
    }

    const newVouchers = convertedItems.filter(item => item.type === currentFilter);
    return newVouchers;
  }, [inventory, currentFilter]);

  const showingModal = useMemo(() => {
    return currentRefKey === 'FILTER_MODAL';
  }, [currentRefKey]);

  const onLinkClicked = useCallback((link: string) => {
    gamefoxSDK.postMessage({
      eventType: 'LINK_CLICKED',
      eventData: {
        link,
      },
    });
  }, []);

  const renderFilterModal = useCallback(() => {
    return (
      <div
        className={cx(classes['filterModal'], showingModal ? classes['on'] : classes['off'])}
        ref={element => {
          (nodeRef as any).current = element;
        }}
      >
        {filters.map((filter, index) => {
          const isSelecting = currentFilter === filter;
          return (
            <div
              className={cx(classes['filterItem'], isSelecting ? 'pro-medium' : 'pro-light')}
              style={{
                backgroundColor: isSelecting ? '#9DC3FF' : '#ffffff',
                color: isSelecting ? 'white' : '#959595',
                borderBottom:
                  index === filters.length - 1
                    ? 'none'
                    : 'calc(var(--game-width) * 0.002) solid #9dc3ff',
              }}
              key={`filter_${index}`}
              onClick={() => {
                setCurrentFilter(filter);
                setCurrentRefKey('');
              }}
            >
              {filterTexts[filter]}
            </div>
          );
        })}
      </div>
    );
  }, [currentFilter, nodeRef, setCurrentRefKey, showingModal]);

  const renderVoucher = useCallback(
    (items: VoucherItem, index: number) => {
      return (
        <div className={classes['voucherItem']}>
          <div className={classes['logoWrapper']}>
            <div className={classes['logoImageWrapper']}>
              <img src={ASSETS.GIFT} alt="" />
            </div>
          </div>
          <div className={classes['infoWrapper']}>
            <div className={cx(classes['voucherName'], 'pro-medium')}>
              eVoucher Got It {VOUCHER_PRICE[items.type]} VNĐ
            </div>
            <div className={cx(classes['voucherDes'], 'pro-regular')}>
              NH Shinhan tặng bạn eVoucher Got It trị giá {VOUCHER_PRICE[items.type]} VNĐ
            </div>
            <div className={cx(classes['voucherDesp'], 'pro-regular')}>
              Vui lòng bấm vào{' '}
              <div
                className={cx(classes['voucherLink'], 'pro-regular')}
                onClick={() => {
                  onLinkClicked(items.data.link);
                }}
              >
                link
              </div>
              để sử dụng
            </div>
            <div className={cx(classes['voucherDate'], 'pro-regular')}>
              Đã nhận: {items.createdAt} <span>|</span> HSD: {items.data.expiredAt}
            </div>
          </div>
          <div className={classes['bottomLine']}>
            <div></div>
          </div>
        </div>
      );
    },
    [onLinkClicked]
  );

  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      <div
        className={classes['container']}
        style={{
          backgroundImage: `url(${ASSETS.SHORT_MODAL_BD})`,
        }}
      >
        <Button title="exit" onClick={onDismiss} customStyle={exitButtonStyle}>
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT})`,
            }}
          ></div>
        </Button>
        <div className={classes['header']}>
          <img src={ASSETS.BASKET_HEADER_BANNER} alt="" />
        </div>

        <div className={classes['filterWrapper']}>
          <div
            className={classes['filterButton']}
            onClick={() => {
              setCurrentRefKey('FILTER_MODAL');
            }}
          >
            <span className="pro-light">{filterTexts[currentFilter]}</span>
            <img src={ASSETS.ARROW} alt="" />
          </div>
          {renderFilterModal()}
        </div>

        <div className={classes['voucherList']}>
          {displayVouchers.length ? (
            displayVouchers.map((voucher, index) => {
              return <div key={`voucher_${index}`}>{renderVoucher(voucher, index)}</div>;
            })
          ) : (
            <div className={classes['emptyList']}>
              <span className="pro-light">Không tìm thấy E - Voucher nào.</span>
            </div>
          )}
          <LoaderOverlay visible={isLoading} />
        </div>
      </div>
    </Modal>
  );
};

const VOUCHER_PRICE = {
  [VoucherType.VOUCHER_0K]: '',
  [VoucherType.VOUCHER_10K]: '10.000',
  [VoucherType.VOUCHER_20K]: '20.000',
  [VoucherType.VOUCHER_50K]: '50.000',
  [VoucherType.VOUCHER_100K]: '100.000',
  [VoucherType.VOUCHER_200K]: '200.000',
  [VoucherType.VOUCHER_500K]: '500.000',
  [VoucherType.VOUCHER_1000K]: '1.000.000',
};
