import { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { BasketModal } from '../../components/modal/basketModal';
import { InstructionModal } from '../../components/modal/InstructionModal';
import { RewardModal } from '../../components/modal/RewardModal';
import { ASSETS } from '../../utils/assetUtils';
import classes from './game.module.css';
import { LuckySpin } from './luckySpin';
import { QuestSection } from './questSection';
import { Button } from '../../components/button/button';
import eventEmitter from '../../utils/eventEmitter';
import { EMITTER_EVENTS } from '../../constants';
import { VoucherItem, VoucherType } from '../../typing';
import { useQuest, useUser } from '../../hooks';
import { OutOfTicketModal } from '../../components/modal/outOfTicketModal';
import { WelcomeModal } from '../../components/modal/welcomeModal';
import gamefoxSDK from '../../gamefoxSDK';

type Props = {
  //
};

export const GameScreen: React.FC<Props> = () => {
  // Modal states
  const [basketModalVisible, setBasketModalVisible] = useState(false);
  const [instructionModalVisible, setInstructionModalVisible] = useState(false);
  const [rewardModalVisible, setRewardModalVisible] = useState(false);
  const [outOfTicketModalVisible, setOutOfTicketModalVisible] = useState(false);

  const [rewardType, setRewardType] = useState<VoucherType | undefined>();
  const [user] = useUser();
  const [quests] = useQuest();

  const noMoreQuest = useMemo(() => quests.every(q => q.progress >= q.goal), [quests]);

  useEffect(() => {
    const handleShowReward = (voucherType: VoucherType) => {
      setRewardType(voucherType);
      setRewardModalVisible(true);
    };
    const handleShowBasket = (reward: VoucherItem) => {
      setBasketModalVisible(true);
    };
    const handleScrollToMission = () => {
      const toLogin = quests
        .filter(quest => quest.name.includes('eKYC'))
        .every(quest => quest.progress >= quest.goal);
      const e = document.getElementById(toLogin ? 'MISSION_LOGIN' : 'MISSION_DETAIL');
      if (e) {
        e.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };
    const handleShowOutOfTicketModal = () => {
      setOutOfTicketModalVisible(true);
    };
    eventEmitter.addListener(EMITTER_EVENTS.SHOW_REWARD, handleShowReward);
    eventEmitter.addListener(EMITTER_EVENTS.SHOW_BASKET, handleShowBasket);
    eventEmitter.addListener(EMITTER_EVENTS.VIEW_DETAIL_MISSION, handleScrollToMission);
    eventEmitter.addListener(EMITTER_EVENTS.SHOW_OUT_OF_TICKET, handleShowOutOfTicketModal);
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.SHOW_REWARD, handleShowReward);
      eventEmitter.removeListener(EMITTER_EVENTS.SHOW_BASKET, handleShowBasket);
      eventEmitter.removeListener(EMITTER_EVENTS.VIEW_DETAIL_MISSION, handleScrollToMission);
      eventEmitter.removeListener(EMITTER_EVENTS.SHOW_OUT_OF_TICKET, handleShowOutOfTicketModal);
    };
  }, [quests]);

  const onExitClicked = useCallback(() => {
    gamefoxSDK.postMessage({
      eventType: 'EXIT_GAME',
      eventData: {},
    });
  }, []);

  return (
    <div className={classes['container']}>
      <img className={classes['background']} src={ASSETS.BACKGROUND} alt="" />
      <img className={classes['light']} src={ASSETS.LIGHT} alt="" />
      <div className={classes['content']}>
        <div className={classes['banner']}>
          <img src={ASSETS.GAME_BANNER} alt="" />
        </div>
        <div className={cx(classes['username'], 'pro-regular')}>
          Xin chào Quý khách&nbsp;
          <span className="pro-regular">
            {user?.data?.username && user.data.username.toUpperCase()}
          </span>
        </div>

        <LuckySpin />

        <div className={classes['buttonRow']}>
          <Button
            title="giftButton"
            onClick={() => {
              setBasketModalVisible(true);
            }}
          >
            <div
              className={classes['actionButton']}
              style={{
                backgroundImage: `url(${ASSETS.LEFT_BLUE_BUTTON})`,
              }}
            >
              <img src={ASSETS.GIFT_ICON} alt="" />
              <span className="pro-regular" style={{ marginLeft: '6px' }}>
                Giỏ quà
              </span>
            </div>
          </Button>
          <img className={classes['logo']} src={ASSETS.LOGO} alt="" />
          <Button
            title="guideButton"
            onClick={() => {
              setInstructionModalVisible(true);
            }}
          >
            <div
              className={cx(classes['actionButton'], classes['rightButton'])}
              style={{
                backgroundImage: `url(${ASSETS.RIGHT_BLUE_BUTTON})`,
              }}
            >
              <img src={ASSETS.INSTRUCTION_ICON} alt="" />
              <span className="pro-regular">Hướng dẫn</span>
            </div>
          </Button>
        </div>

        <QuestSection />

        {/* <Button
          title="exit"
          onClick={onExitClicked}
          customStyle={{
            marginTop: `calc(var(--game-width) * 0.32)`,
            marginBottom: `calc(var(--game-width) * 0.1)`,
          }}
        >
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT_BUTTON})`,
            }}
          >
            <span className="pro-regular">Thoát trò chơi</span>
          </div>
        </Button> */}
      </div>

      <BasketModal
        visible={basketModalVisible}
        onDismiss={() => {
          setBasketModalVisible(false);
        }}
      />

      <InstructionModal
        visible={instructionModalVisible}
        onDismiss={() => {
          setInstructionModalVisible(false);
        }}
      />

      <RewardModal
        rewardType={rewardType}
        visible={rewardModalVisible}
        onDismiss={() => {
          setRewardModalVisible(false);
        }}
      />

      <OutOfTicketModal
        visible={outOfTicketModalVisible}
        noMoreQuest={noMoreQuest}
        onDismiss={() => {
          setOutOfTicketModalVisible(false);
        }}
      />

      <WelcomeModal />
    </div>
  );
};
