import { StoreProvider } from 'easy-peasy';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/layout';
import { NoticeProvider } from './contexts/NoticeContext';
import { useConfig, useUser } from './hooks';
import { GameScreen } from './screens/game/game';
import { LoadingScreen } from './screens/loading/loading';
import store from './store';
import { soundUtils } from './utils/soundUtils';

function App() {
  const [user] = useUser();
  const [config] = useConfig();

  useEffect(() => {
    soundUtils.setup();
    return () => {
      soundUtils.dispose();
    };
  }, []);

  return (
    <BrowserRouter basename={process.env.REACT_APP_APP_URL}>
      <Layout>
        {!user || !config ? (
          <Routes>
            <Route path="/" element={<LoadingScreen />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<LoadingScreen />} />
            <Route path="/game" element={<GameScreen />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </Layout>
    </BrowserRouter>
  );
}

const WrappedApp = () => {
  return (
    <StoreProvider store={store}>
      <NoticeProvider>
        <App />
      </NoticeProvider>
    </StoreProvider>
  );
};

export default WrappedApp;
