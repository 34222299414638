import cx from 'classnames';
import { CSSProperties } from 'react';
import { Button } from '../../components/button/button';
import { Modal } from '../../components/modal/modal';
import { ASSETS } from '../../utils/assetUtils';

import classes from './noticeModal.module.css';

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: `calc(var(--game-width) * -0.1)`,
};

type Props = {
  visible: boolean;
  notice: string;
  onDismiss: () => void;
  closeable: boolean;
};

export const NoticeModal: React.FC<Props> = ({ visible, onDismiss, notice, closeable }) => {
  return (
    <Modal visible={visible} onDismiss={onDismiss} zIndex={1000000000}>
      <div
        className={classes['container']}
        style={{ backgroundImage: `url(${ASSETS.NOTICE_MODAL_BD})` }}
      >
        {closeable && (
          <Button title="close" onClick={onDismiss} customStyle={exitButtonStyle}>
            <div
              className={classes['closeButton']}
              style={{
                backgroundImage: `url(${ASSETS.EXIT})`,
              }}
            ></div>
          </Button>
        )}

        <div className={cx(classes['title'], 'pro-medium')}>{notice}</div>
        {closeable && (
          <div className={classes['actionRow']}>
            <Button title="keep" onClick={onDismiss}>
              <div className={cx(classes['keepButton'], 'pro-bold')}>OK</div>
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
