import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import classe from './game.module.css';
import { Button } from '../../components/button/button';
// import { EventRawTimer } from '../../components/timer/eventRawTimer';
// import classes from './InstructionModal.module.css';
// import { CAMPAIGN_END_DATE, CAMPAIGN_START_DATE } from '../../constants';
import gamefoxSDK from '../../gamefoxSDK';
import { useQuest, useSystemInfo } from '../../hooks';
import { ASSETS } from '../../utils/assetUtils';
import config from '../../config';

import classes from './questSection.module.css';

type Props = {
  //
};

export const QuestSection: React.FC<Props> = () => {
  const onExitClicked = useCallback(() => {
    gamefoxSDK.postMessage({
      eventType: 'EXIT_GAME',
      eventData: {},
    });
  }, []);
  const onLinkClicked = useCallback((link: string) => {
    gamefoxSDK.postMessage({
      eventType: 'LINK_CLICKED',
      eventData: {
        link,
      },
    });
  }, []);
  const [systemInfos] = useSystemInfo();
  const [quests] = useQuest();

  const ekycQuests = useMemo(() => {
    const qs = quests.filter(quest => quest.name.includes('eKYC'));
    return qs;
  }, [quests]);

  const doQuest = useCallback(() => {
    const nextQuestIdx = ekycQuests.findIndex(quest => quest.progress < quest.goal);
    if (nextQuestIdx > -1) {
      gamefoxSDK.postMessage({
        eventType: 'DO_QUEST',
        eventData: {
          ...ekycQuests[nextQuestIdx],
          key: ekycQuests[nextQuestIdx].name,
        },
      });
    }
  }, [ekycQuests]);
  const doLoginQuest = useCallback(() => {
    const loginQuest = quests.find(quest => quest.name.includes('Đăng nhập'));
    if (loginQuest) {
      gamefoxSDK.postMessage({
        eventType: 'DO_QUEST',
        eventData: {
          ...loginQuest,
          key: 'daily-login',
        },
      });
    }
  }, [quests]);

  const hasCompletedEkyc = useMemo(
    () => ekycQuests.every(quest => quest.progress >= quest.goal),
    [ekycQuests]
  );
  const hasLogin = useMemo(() => {
    const loginQuest = quests.find(quest => !quest.name.includes('eKYC'));
    if (!loginQuest) {
      return false;
    }
    return loginQuest.progress >= loginQuest.goal;
  }, [quests]);

  return (
    <div className={classes['container']} id="MISSION_DETAIL">
      <div className={classes['wrapper']}>
        {/* <div className={cx(classes['title'], 'pro-regular')}>Thể lệ chương trình </div> */}
        <div className={classes['section']}>
          <div className={classes['sectionHeader']}>
            <div className={classes['headerIconWrapper']}>
              <img src={ASSETS.METHOD1_ICON} alt="" />
            </div>
            <div className={classes['headerInfoWrapper']}>
              <div className={cx(classes['headerTitle'], 'pro-regular')}>Thể lệ chương trình</div>
              {/* <div className={cx(classes['headerDes'], 'pro-regular')}>
                01 lượt quay/bước (tối đa 06 lượt quay)
              </div> */}
            </div>
          </div>
          <div className={cx(classes['sectionDes'], 'pro-regular')}>
            Nhân kỷ niệm 30 năm NH Shinhan Việt Nam có mặt tại Việt Nam, nhằm tri ân đến Khách hàng
            trong suốt thời gian qua đã tin tưởng và đồng hành cùng NH, từ ngày 15/08/2023 đến hết
            ngày 13/09/2023, NH triển khai chương trình{' '}
            <span style={{ color: 'blue' }}>"Thay Lời Cảm Ơn"</span> dành cho Khách hàng đang có
            khoản vay còn dư nợ hiện hữu thỏa điều kiện (tính đến ngày 30/06/2023).
            <br />
            Theo đó, các Khách hàng thỏa điều kiện của chương trình sẽ được hệ thống tự động cấp
            lượt quay tương ứng với mức dư nợ đang có:
            {/* Người chơi sẽ nhận tối đa 06 lượt quay tương ứng với 06 bước trong quy trình mở tài
            khoản bằng phương thức định danh điện tử (eKYC) trên Ứng dụng Shinhan SOL Việt Nam ("Ứng
            dụng SOL") trong thời gian quy định (Từ ngày {CAMPAIGN_START_DATE} đến ngày{' '}
            {CAMPAIGN_END_DATE}). */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  // width: '20%',
                  float: 'left',
                  boxSizing: 'border-box',
                }}
              >
                <div>
                  <h1
                    style={{
                      color: '#376cfa',
                      fontSize: '15px',
                      marginLeft: '20px',
                    }}
                  >
                    Dư nợ (X)
                    <br />
                    <span style={{ marginLeft: '9px', fontSize: '14px' }}>(VND)</span>
                  </h1>
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <p style={{ fontSize: '13px', fontWeight: '100' }}>
                    {' '}
                    <span style={{ fontSize: '15px', margin: '2px' }}>{'≤'}</span>01 tỷ đồng
                  </p>
                  <p style={{ fontSize: '13px', fontWeight: '100' }}>
                    01 tỷ đồng <span style={{ fontSize: '15px' }}>{'<'}</span> X{' '}
                    <span style={{ fontSize: '16px', margin: '2px' }}>{'≤'}</span>03 tỷ đồng
                  </p>
                  <p style={{ fontSize: '13px', fontWeight: '100' }}>
                    <span style={{ fontSize: '15px' }}>{'>'}</span> 03 tỷ đồng
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '30%',
                  float: 'right',
                  boxSizing: 'border-box',
                }}
              >
                <div>
                  <h1 style={{ color: '#376cfa', fontSize: '15px' }}>
                    Lượt quay
                    <br />
                    <span style={{ fontSize: '14px' }}> (lượt/ngày)</span>
                  </h1>
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <p style={{ fontSize: '13px', fontWeight: '100', marginTop: '13px' }}>02 </p>
                  <p style={{ fontSize: '13px', fontWeight: '100', marginTop: '20px' }}>03 </p>
                  <p style={{ fontSize: '13px', fontWeight: '100' }}>04 </p>
                </div>
              </div>
            </div>
            Quý khách truy cập vào ứng dụng Shinhan SOL Việt Nam (“ứng dụng SOL”) để xem số lượt
            quay được hệ thống gửi tặng và tiến hành quay số.
          </div>
          <div className={classes['stepsWrapper']}>
            {ekycQuests.map((step, index) => {
              const isComplete = index < 3 || step.progress >= step.goal;

              return (
                <div className={classes['stepItem']} key={`step_${index}`}>
                  <div
                    className={classes['stepStatusIcon']}
                    style={{
                      backgroundColor: isComplete ? '#FCA425' : '#BEC1C6',
                    }}
                  >
                    {isComplete ? (
                      <img src={ASSETS.CHECK_ICON} alt="" />
                    ) : (
                      <span className="sh-regular">+{step.goal}</span>
                    )}
                  </div>
                  <div
                    className={cx(classes['stepDes'], 'pro-regular')}
                    style={{
                      color: isComplete ? '#FCA425' : '#74767d',
                    }}
                  >
                    {step.description}
                  </div>
                  {index !== ekycQuests.length - 1 && (
                    <div className={classes['stepConnector']}></div>
                  )}
                </div>
              );
            })}
          </div>
          <div style={{ textAlign: 'center' }}>
            <h1
              style={{
                color: 'red',
                fontSize: '15px',
                fontWeight: '500',
                margin: '0',
                whiteSpace: 'nowrap',
              }}
            >
              Tổng giải thưởng trị giá 100 triệu đồng.
            </h1>
          </div>

          <div
            style={{ marginLeft: '-66px', color: 'blue', fontWeight: '400' }}
            // className={cx(classes['ruleText4'], 'pro-regular')}
            onClick={() => onLinkClicked(config.shareUrl)}
          >
            <span>Chi tiết </span>
            <span>[Thể lệ chương trình]</span>
          </div>
          <Button
            title="exit"
            onClick={onExitClicked}

            // customStyle={{
            //   marginTop: `calc(var(--game-width) * 0.32)`,
            //   marginBottom: `calc(var(--game-width) * 0.1)`,
            // }}
          >
            <div
              className={classe['exitButton']}
              style={{
                backgroundImage: `url(${ASSETS.EXIT_BUTTON})`,
                marginTop: '20px',
              }}
            >
              <span className="pro-regular">Thoát trò chơi</span>
            </div>
          </Button>
        </div>

        <div className={classes['deco']} style={{ margin: '-58px' }}>
          <img src={ASSETS.BOTTOM_DECO} alt="" />
        </div>
      </div>
    </div>
  );
};
