import { Vector2 } from 'gdxts';

export const dist = (x1: number, y1: number, x2: number, y2: number) => {
  return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
};

export const randomRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const randomRangeInt = (min: number, max: number) => {
  return Math.floor(randomRange(min, max));
};

export const randomInArray = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)] as T;
};

export const shuffleArray = <T extends any = any>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export const lerpVector2 = (
  output: Vector2,
  value1: Vector2,
  value2: Vector2,
  alpha: number
): Vector2 => {
  return output.setVector(value2).subVector(value1).scale(alpha).addVector(value1);
};

export const pointInRect = (
  position: { x: number; y: number },
  x: number,
  y: number,
  width: number,
  height: number
): boolean => {
  return position.x > x && position.x < x + width && position.y > y && position.y < y + height;
};

export const lerp = (start: number, end: number, alpha: number): number => {
  return start + (end - start) * alpha;
};
