import 'normalize.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { setStyles } from './utils/styleUtils';

// Simulate time
// import { timeUtil, TIME_ZONE } from './utils/timeUtils';
// import dayjs from './utils/dayjs';

// let _simulateTime = dayjs('18/05/2023 19:59:50', 'DD/MM/YYYY HH:mm:ss', TIME_ZONE);
// timeUtil.getCurrentTime = () => {
//   return _simulateTime;
// };
// setInterval(() => {
//   _simulateTime = _simulateTime.add(1, 'seconds');
// }, 1000);
// End simulate time

setStyles();
window.addEventListener('resize', () => {
  setStyles();
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// serviceWorkerRegistration.register();
