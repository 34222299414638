import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { ASSETS } from '../../utils/assetUtils';
import { getPolicyApproved, setPolicyApproved } from '../../utils/storage';
import { Button } from '../button/button';
import { Modal } from './modal';
import classes from './welcomeModal.module.css';
import gamefoxSDK from '../../gamefoxSDK';
import config from '../../config';

export const WelcomeModal = () => {
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    const policyApproved = getPolicyApproved();
    if (typeof policyApproved !== 'boolean') {
      setVisible(true);
    }
  }, []);

  const dismiss = useCallback(() => {
    setVisible(false);
  }, []);

  const playNow = useCallback(() => {
    if (!checked) return;
    setPolicyApproved(true);
    setVisible(false);
  }, [checked]);

  const pressCheckbox = useCallback(() => {
    setChecked(checked => !checked);
  }, []);

  const renderCheckbox = useCallback(() => {
    return checked ? (
      <div className={classes['checkbox1']}>
        <img src={ASSETS.CHECKED} alt="" />
      </div>
    ) : (
      <div className={classes['checkbox2']}></div>
    );
  }, [checked]);

  const onLinkClicked = useCallback((link: string) => {
    gamefoxSDK.postMessage({
      eventType: 'LINK_CLICKED',
      eventData: {
        link,
      },
    });
  }, []);

  return (
    <Modal visible={visible} onDismiss={dismiss} zIndex={100000} dismissOnBackdropClick={false}>
      <div
        className={classes['container']}
        style={{ backgroundImage: `url(${ASSETS.WELCOME_BD})` }}
      >
        <div className={classes['deco']}>
          <img src={ASSETS.WELCOME_DECO} alt="" />
        </div>
        <div className={classes['content']}>
          <div className={classes['checkboxWrapper']} onClick={pressCheckbox}>
            {renderCheckbox()}
          </div>
          <div className={classes['texts']}>
            <span className="pro-regular">Tôi đồng ý với thể lệ chương trình</span>
            <span className="pro-regular" onClick={() => onLinkClicked(config.ruleGuideUrl)}>
              Xem chi tiết [Thể lệ chương trình]
            </span>
          </div>
        </div>
        <div className={classes['buttonRow']}>
          <Button disabled={!checked} title="playnow" onClick={playNow}>
            <div
              className={cx(classes['playNowButton'], checked && classes['activeButton'])}
              style={{
                backgroundImage: `url(${checked ? ASSETS.ACTIVE_BUTTON : ASSETS.DEACTIVE_BUTTON})`,
              }}
            >
              <span className="pro-regular">Chơi ngay</span>
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
