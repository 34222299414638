import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { setClientResolution } from '../../utils/storage';
import { setStyles } from '../../utils/styleUtils';
import classes from './layout.module.css';
import { FixedTopContent } from '../../screens/game/fixedTopContent';

const Layout = ({ children }: { children?: React.ReactNode | React.ReactNode[] }) => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const width = params.get('width');
    const height = params.get('height');
    const padTop = params.get('padTop');
    const padBottom = params.get('padBottom');
    if (width && height && !isNaN(parseFloat(width)) && !isNaN(parseFloat(height))) {
      setClientResolution(
        parseFloat(width),
        parseFloat(height),
        !!padTop && !isNaN(parseFloat(padTop)) ? parseFloat(padTop) : undefined,
        !!padBottom && !isNaN(parseFloat(padBottom)) ? parseFloat(padBottom) : undefined
      );
      setStyles();
    }
  }, [location.search]);

  const hideTopContent = useMemo(() => {
    return !location.pathname.includes('/game');
  }, [location]);

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={cx(classes.content)}>
          {!hideTopContent && <FixedTopContent />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
