import { Modal } from './modal';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import bezier from 'bezier-easing';
import cx from 'classnames';

import { Button } from '../button/button';
import { ASSETS, VOUCHER_IMAGES } from '../../utils/assetUtils';
import classes from './RewardModal.module.css';
import { VoucherType } from '../../typing';
import eventEmitter from '../../utils/eventEmitter';
import { EMITTER_EVENTS, VOUCHER_VALUES } from '../../constants';
import { SoundFxId, soundUtils } from '../../utils/soundUtils';
import { randomRangeInt } from '../../utils/math';

type Props = {
  rewardType?: VoucherType;
  visible: boolean;
  onDismiss: () => void;
};

const easing = bezier(0.61, 0.16, 0.24, 1.53);

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  right: 0,
  top: `calc(var(--game-width) * -0.14)`,
};

const luckyWishes = [
  {
    title: 'Quý khách nhận được lời chúc',
    content: 'Chúc bạn may mắn lần sau.',
  },
  {
    title: 'Quý khách nhận được lời chúc',
    content: 'Tuần mới công việc phơi phới, may mắn tấn tới trong lần sau nhé.',
  },
  {
    title: 'Quý khách nhận được lời chúc',
    content: 'Hành trình phía trước thuận lợi, đợi may mắn lần sau nhé.',
  },
];

export const RewardModal: React.FC<Props> = ({ rewardType, visible, onDismiss }) => {
  const [randomLw, setRandomLw] = useState(0);
  const [showGlow, setShowGlow] = useState(false);
  const [showedGlow, setShowedGlow] = useState(false);
  const initial = useRef<boolean>();

  useEffect(() => {
    if (visible) {
      if (rewardType !== VoucherType.VOUCHER_0K) {
        soundUtils.play(SoundFxId.REWARD);
      } else {
        setRandomLw(randomRangeInt(0, luckyWishes.length));
        soundUtils.play(SoundFxId.LUCKY_WISH);
      }
    }
  }, [visible, rewardType]);

  useEffect(() => {
    if (!visible) {
      initial.current = false;
    }
  }, [visible]);

  useEffect(() => {
    let timeout: any;
    let showGlowTimeout: any;

    if (visible) {
      timeout = setTimeout(() => {
        setShowGlow(true);
        showGlowTimeout = setTimeout(() => {
          setShowedGlow(true);
        }, 1500);
      }, 600);
    } else {
      setShowGlow(false);
      setShowedGlow(false);
    }

    return () => {
      timeout && clearTimeout(timeout);
      showGlowTimeout && clearTimeout(showGlowTimeout);
    };
  }, [visible]);

  const headerTransition = useTransition(visible, {
    delay: 300,
    config: { duration: 400, easing: t => easing(t) },
    from: { opacity: 0, transform: 'scaleX(0.4) scaleY(0.4)' },
    enter: { opacity: 1, transform: 'scaleX(1) scaleY(1)' },
  });

  const contentTransition = useTransition(visible, {
    delay: 300,
    config: { duration: 400 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });

  const renderLuckyWish = useCallback(() => {
    return contentTransition((style: any, visible: boolean) => {
      if (!visible) return;
      return (
        <animated.div className={classes['luckyWishWrapper']} style={style}>
          <div className={cx(classes['luckyWishTitle'], 'pro-regular')}>
            {luckyWishes[randomLw].title}
          </div>
          <div className={classes['luckyWishContent']}>
            <img src={ASSETS.TRASH_BANNER} alt="" />
            <div className="pro-regular">{luckyWishes[randomLw].content}</div>
          </div>
        </animated.div>
      );
    });
  }, [contentTransition, randomLw]);

  const renderReward = useCallback(() => {
    if (!rewardType) return null;

    return contentTransition((style: any, visible: boolean) => {
      if (!visible) return;
      return (
        <animated.div className={classes['rewardWrapper']} style={style}>
          <div className={cx(classes['rewardTitle'], 'pro-light')}>Quý khách đã trúng thưởng</div>
          <div className={classes['rewardIcon']}>
            <div>
              <img src={VOUCHER_IMAGES[rewardType]} alt="" />
            </div>
            <img src={ASSETS.VOUCHER_SHADOW} alt="" />
          </div>
          <div className={cx(classes['rewardName'], 'pro-regular')}>eVoucher</div>
          <div className={cx(classes['rewardPrize'], 'pro-regular')}>
            trị giá {VOUCHER_VALUES[rewardType]}
          </div>
        </animated.div>
      );
    });
  }, [contentTransition, rewardType]);

  return (
    <Modal visible={visible} onDismiss={onDismiss} dismissOnBackdropClick={false}>
      <div
        className={classes['container']}
        style={{
          backgroundImage: `url(${ASSETS.REWARD_MODAL_BD})`,
        }}
      >
        <Button title="exit" onClick={onDismiss} customStyle={exitButtonStyle}>
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT})`,
            }}
          ></div>
        </Button>
        <div
          className={cx(
            classes['headerGlowWrapper'],
            !showGlow
              ? classes['hideGlow']
              : showedGlow
              ? classes['showedGlow']
              : classes['showingGlow']
          )}
        >
          <img className={classes['headerGlow']} src={ASSETS.REWARD_HEADER_GLOW} alt="" />
        </div>
        {headerTransition((style: any, visible: any) => {
          if (!visible) return null;
          return (
            <animated.div
              className={classes['header']}
              style={style}
              key={`header${Math.floor(Math.random() * 100)}`}
            >
              <div>
                <img className={classes['headerBanner']} src={ASSETS.REWARD_HEADER_BANNER} alt="" />
              </div>
              <div className={cx(classes['headerText'], 'pro-regular')}>CHÚC MỪNG</div>
            </animated.div>
          );
        })}
        {rewardType === VoucherType.VOUCHER_0K ? renderLuckyWish() : renderReward()}
        {rewardType !== VoucherType.VOUCHER_0K && (
          <Button
            title="viewReward"
            customStyle={{
              position: 'absolute',
              bottom: `calc(var(--game-width) * -0.215)`,
            }}
            onClick={() => {
              onDismiss();
              eventEmitter.emit(EMITTER_EVENTS.SHOW_BASKET);
            }}
          >
            <div
              className={cx(classes['viewRewardButton'], 'pn-bold')}
              style={{ backgroundImage: `url(${ASSETS.BLUE_BUTTON})` }}
            >
              <img src={ASSETS.GIFT_ICON} alt="" />
              <span className="pro-semibold">Giỏ quà</span>
            </div>
          </Button>
        )}
      </div>
    </Modal>
  );
};
