import cx from 'classnames';
import BouncingDots from './BouncingDots';
import classes from './loaderOverlay.module.css';

type Props = {
  visible: boolean;
};

export const LoaderOverlay: React.FC<Props> = ({ visible }) => {
  return (
    <div className={cx(classes['container'], visible && classes['visible'])}>
      <span className="pro-medium">Đang cập nhật</span>
      <BouncingDots />
    </div>
  );
};
