const config = {
  online: true,
  // prod
  // apiUrl: 'https://shinhan.gamefox.vn',
  // apiUrl: 'http://localhost:3004',
  apiUrl: 'https://shinhan-user-api-dev.gamefox.vn',
  // // test
  // apiUrl: 'https://shinhan-user-api.gamefox.vn',
  shareUrl: 'https://shinhan.com.vn/vi/promotion/vao-sol-quay-so-nhan-qua-vo-so.html',
  ruleGuideUrl:
    'https://shinhan.com.vn/public/uploads/Promotion/2023/RLD/Game/Term%20and%20condition_final%20pdf%20090823.pdf',
  voucherGotItGuideUrl:
    'https://shinhan.com.vn/public/uploads/Promotion/2023/RLD/Game/HDSD%20-%20E-VOUCHER%20GOT%20IT%20(final).pdf',
};

export default config;
