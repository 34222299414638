import './BouncingDots.css';

const BouncingDots = () => {
  return (
    <div className="bouncing-dots">
      <div className="dot1" />
      <div className="dot2" />
      <div className="dot3" />
    </div>
  );
};

export default BouncingDots;
