import { Modal } from './modal';
import cx from 'classnames';

import { Button } from '../button/button';
import { ASSETS } from '../../utils/assetUtils';
import classes from './outOfTicketModal.module.css';
import eventEmitter from '../../utils/eventEmitter';
import { EMITTER_EVENTS } from '../../constants';
import { CSSProperties } from 'react';

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: `calc(var(--game-width) * -0.1)`,
};

type Props = {
  visible: boolean;
  noMoreQuest: boolean;
  onDismiss: () => void;
};

export const OutOfTicketModal: React.FC<Props> = ({ visible, onDismiss, noMoreQuest }) => {
  return (
    <Modal visible={visible} onDismiss={onDismiss} dismissOnBackdropClick={false}>
      <div
        className={classes['container']}
        style={{
          backgroundImage: `url(${ASSETS.REWARD_MODAL_BD})`,
        }}
      >
        <Button title="exit" onClick={onDismiss} customStyle={exitButtonStyle}>
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT})`,
            }}
          ></div>
        </Button>
        <div
          className={classes['content']}
          style={{
            backgroundImage: `url(${
              noMoreQuest ? ASSETS.OUT_OF_TICKET_QUEST : ASSETS.OUT_OF_TICKET_BANNER
            })`,
          }}
        ></div>
        {noMoreQuest ? (
          <Button
            title="backButton"
            customStyle={{
              position: 'absolute',
              bottom: `calc(var(--game-width) * -0.215)`,
            }}
            onClick={onDismiss}
          >
            <div
              className={classes['ruleBackButton']}
              style={{
                backgroundImage: `url(${ASSETS.BACK_BUTTON})`,
              }}
            ></div>
          </Button>
        ) : (
          <Button
            title="viewReward"
            customStyle={{
              position: 'absolute',
              bottom: `calc(var(--game-width) * -0.215)`,
            }}
            onClick={() => {
              onDismiss();
              eventEmitter.emit(EMITTER_EVENTS.VIEW_DETAIL_MISSION);
            }}
          >
            <div
              className={cx(classes['viewRewardButton'], 'pn-bold')}
              style={{ backgroundImage: `url(${ASSETS.BLUE_BUTTON})` }}
            >
              <span className="pro-regular">Thể lệ chương trình</span>
            </div>
          </Button>
        )}
      </div>
    </Modal>
  );
};
